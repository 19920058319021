
    import { Component, Vue } from 'nuxt-property-decorator';
    import Header from '@/layouts/admin/Header.vue';
    import Footer from '@/layouts/admin/Footer.vue';

    @Component({
        // @ts-ignore
        components: {
            Footer,
            Header
        },
        middleware: 'authenticated'
    })
    export default class Admin extends Vue {}
