import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class WindowMixin extends Vue {
    windowWidth = 0;
    isMobile = false;

    mounted() {
        if (window !== undefined) {
            this.windowWidth = window.innerWidth;
            this.isMobile = this.windowWidth < 992;
            window.addEventListener('resize', this.updateWidth);
        }
    }

    updateWidth() {
        this.windowWidth = window.innerWidth;
        this.isMobile = this.windowWidth < 992;
    }

    beforeDestroy() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.updateWidth);
        }
    }
}
