import Icon from 'vue-awesome/components/Icon.vue';
// @ts-ignore
Icon.register({
    pinq_tg: {
        width: 25,
        height: 25,
        paths: [
            {
                d:
                    'M12.5 23.7603C18.7189 23.7603 23.7603 18.7189 23.7603 12.5C23.7603 6.28109 18.7189 1.23967 12.5 1.23967C6.28109 1.23967 1.23967 6.28109 1.23967 12.5C1.23967 18.7189 6.28109 23.7603 12.5 23.7603ZM12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z',
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd'
            },
            {
                d:
                    'M16.7438 7.11695C16.9324 7.24708 17.0281 7.46275 16.9927 7.67791L15.3773 17.4993C15.3416 17.7166 15.178 17.8975 14.9531 17.9684C14.7282 18.0394 14.4792 17.9887 14.3073 17.8369L12.4275 16.1764L9.96344 17.3457C9.7875 17.4292 9.57963 17.4324 9.40077 17.3544C9.22192 17.2764 9.09237 17.126 9.05011 16.9473L8.37412 14.0893L5.36782 12.7924C5.1438 12.6958 5.00043 12.488 5 12.2594C4.99957 12.0308 5.14216 11.8226 5.36581 11.7252L16.0915 7.05611C16.3044 6.96343 16.5552 6.98682 16.7438 7.11695ZM11.4519 15.3147L10.082 14.1047C9.83416 13.8858 9.81799 13.5247 10.0455 13.2875L13.8279 9.34409L7.13885 12.256L9.19855 13.1445C9.37861 13.2222 9.50919 13.3731 9.55165 13.5526L10.1181 15.9476L11.4519 15.3147ZM15.4086 9.47206L11.4063 13.6447L14.3017 16.2021L15.4086 9.47206Z',
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd'
            }
        ]
    }
});
