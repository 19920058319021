import Icon from 'vue-awesome/components/Icon.vue';
// @ts-ignore
Icon.register({
    pinq_youtube: {
        width: 25,
        height: 25,
        paths: [
            {
                d:
                    'M14.9375 8.24528H10.0625C8.49194 8.24528 7.21875 9.54619 7.21875 11.1509V13.8491C7.21875 15.4538 8.49194 16.7547 10.0625 16.7547H14.9375C16.5081 16.7547 17.7812 15.4538 17.7812 13.8491V11.1509C17.7812 9.54619 16.5081 8.24528 14.9375 8.24528ZM10.0625 7C7.81884 7 6 8.85844 6 11.1509V13.8491C6 16.1416 7.81884 18 10.0625 18H14.9375C17.1812 18 19 16.1416 19 13.8491V11.1509C19 8.85844 17.1812 7 14.9375 7H10.0625Z',
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd'
            },
            {
                d:
                    'M14.7241 12.1779C15.092 12.321 15.092 12.679 14.7241 12.8221L11.8276 13.9496C11.4598 14.0928 11 13.9138 11 13.6275L11 11.3725C11 11.0862 11.4598 10.9072 11.8276 11.0504L14.7241 12.1779Z'
            },
            {
                d:
                    'M12.5 23.7603C18.7189 23.7603 23.7603 18.7189 23.7603 12.5C23.7603 6.28109 18.7189 1.23967 12.5 1.23967C6.28109 1.23967 1.23967 6.28109 1.23967 12.5C1.23967 18.7189 6.28109 23.7603 12.5 23.7603ZM12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z',
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd'
            }
        ]
    }
});
