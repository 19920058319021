import Icon from 'vue-awesome/components/Icon.vue';
// @ts-ignore
Icon.register({
    pinq_fb: {
        width: 25,
        height: 25,
        paths: [
            {
                d:
                    'M15.8192 10.3458H13.6177V8.73904C13.6177 8.24619 14.1275 8.13189 14.3655 8.13189C14.6018 8.13189 15.7876 8.13189 15.7876 8.13189V5.96358L14.1577 5.95508C11.9338 5.95508 11.4262 7.57185 11.4262 8.60852V10.3458H9.8125V12.5798H11.4262C11.4262 15.448 11.4262 18.6112 11.4262 18.6112H13.6177C13.6177 18.6112 13.6177 15.4155 13.6177 12.5798H15.4793L15.8192 10.3458Z'
            },
            {
                d:
                    'M12.5 23.7603C18.7189 23.7603 23.7603 18.7189 23.7603 12.5C23.7603 6.28109 18.7189 1.23967 12.5 1.23967C6.28109 1.23967 1.23967 6.28109 1.23967 12.5C1.23967 18.7189 6.28109 23.7603 12.5 23.7603ZM12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z',
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd'
            }
        ]
    }
});
