import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class Accordion extends Vue {
    @Prop() index;
    private readonly idPrefix = '#collapse-q-';
    currentExpanded = 0;

    buildId(index: number): string {
        return `${this.idPrefix}-${index.toString()}`;
    }

    mounted() {
        this.$root.$on('bv::collapse::state', (collapseId, isShow) => {
            if (isShow) {
                this.currentExpanded = Number(
                    collapseId.substring(this.idPrefix.length + 1)
                );
            }
        });
    }

    beforeDestroy() {
        this.$root.$off('bv::collapse::state');
    }
}
