import { Component, Vue } from 'nuxt-property-decorator';
import { PagePalette } from '@/store/page';

@Component
export default class PaletteMixin extends Vue {
    get palette(): PagePalette {
        return this.$store.state[this.getPageStore()].options.palette;
    }

    getPageStore() {
        if (this.$store.state['editor'].currentPage) {
            return 'editor';
        } else {
            return 'page';
        }
    }

    get backgroundBg() {
        return this.getBackground(this.palette.bg);
    }

    get backgroundIcon() {
        return this.getBackground(this.palette.icon);
    }

    get backgroundTrigger() {
        return this.getBackground(this.palette.trigger);
    }

    get backgroundText() {
        return this.getBackground(this.palette.text);
    }

    get borderBg() {
        return this.getBorder(this.palette.bg);
    }

    get borderIcon() {
        return this.getBorder(this.palette.icon);
    }

    get borderTrigger() {
        return this.getBorder(this.palette.trigger);
    }

    get borderText() {
        return this.getBorder(this.palette.text);
    }

    get colorBg() {
        return this.getColor(this.palette.bg);
    }

    get colorIcon() {
        return this.getColor(this.palette.icon);
    }

    get colorTrigger() {
        return this.getColor(this.palette.trigger);
    }

    get colorText() {
        return this.getColor(this.palette.text);
    }

    private getBackground = (color: string): object => {
        return { 'background-color': color };
    };

    private getBorder = (color: string): object => {
        return { 'border-color': color };
    };

    private getColor = (color: string): object => {
        return { color: color };
    };
}
