import Icon from 'vue-awesome/components/Icon.vue';
// @ts-ignore
Icon.register({
    pinq_im: {
        width: 25,
        height: 25,
        paths: [
            {
                d:
                    'M16.5254 1.27119H8.47458C4.49625 1.27119 1.27119 4.49625 1.27119 8.47458V16.5254C1.27119 20.5037 4.49625 23.7288 8.47458 23.7288H16.5254C20.5037 23.7288 23.7288 20.5037 23.7288 16.5254V8.47458C23.7288 4.49625 20.5037 1.27119 16.5254 1.27119ZM8.47458 0C3.7942 0 0 3.7942 0 8.47458V16.5254C0 21.2058 3.7942 25 8.47458 25H16.5254C21.2058 25 25 21.2058 25 16.5254V8.47458C25 3.7942 21.2058 0 16.5254 0H8.47458Z',
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd'
            },
            {
                d:
                    'M12.4997 16.7699C14.8581 16.7699 16.7699 14.8581 16.7699 12.4997C16.7699 10.1414 14.8581 8.22956 12.4997 8.22956C10.1414 8.22956 8.22956 10.1414 8.22956 12.4997C8.22956 14.8581 10.1414 16.7699 12.4997 16.7699ZM12.4997 18.478C15.8014 18.478 18.478 15.8014 18.478 12.4997C18.478 9.19804 15.8014 6.52148 12.4997 6.52148C9.19804 6.52148 6.52148 9.19804 6.52148 12.4997C6.52148 15.8014 9.19804 18.478 12.4997 18.478Z',
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd'
            },
            {
                d:
                    'M20.6524 6.5225C20.6524 7.12281 20.1658 7.60946 19.5655 7.60946C18.9652 7.60946 18.4785 7.12281 18.4785 6.5225C18.4785 5.92219 18.9652 5.43555 19.5655 5.43555C20.1658 5.43555 20.6524 5.92219 20.6524 6.5225Z'
            }
        ]
    }
});
