import { Component, Vue } from 'nuxt-property-decorator';
import 'bootstrap-vue/src/components/modal/index';

export enum HandleType {
    Modal,
    Toast
}

@Component
export default class HandleResultMixin extends Vue {
    handleResult(result, handleType: HandleType, modalId?) {
        switch (handleType) {
            case HandleType.Modal:
                this.$bvModal.show(modalId);
                break;
            case HandleType.Toast:
                this.$bvToast.toast(result.data.message, {
                    title: 'Результат операции',
                    autoHideDelay: 1000
                });
                break;
        }
    }
}
