
    import { Component, Prop } from 'nuxt-property-decorator';
    import { namespace } from 'nuxt-property-decorator';
    import { PageComponent } from '@/store/page';
    import PageMixin from '@/mixins/PageMixin.vue';

    import components from '@/components';

    const page = namespace('page');

    @Component({
        components: components
    })
    export default class Error extends PageMixin {
        @Prop() error;
        @page.State components: PageComponent[];
        @page.Action fetch;
        params = null;

        async mounted() {
            if (this.error.statusCode === 404) {
                this.fetch({ slug: '404' });
            } else {
                this.fetch({ slug: 'error' });
            }
        }
    }
