import { Component, Vue } from 'nuxt-property-decorator';
import isObj from 'is-obj';

@Component
export default class ImageMixin extends Vue {
    imageSrc(src) {
        let resultSrc = '';
        if (src && !isObj(src) && src.indexOf('uploads/') !== -1) {
            resultSrc = `/${src}`;
        } else {
            resultSrc = src;
        }

        return resultSrc;
    }

    bgImage(src) {
        return `background-image: url('${this.imageSrc(src)}')`;
    }
}
