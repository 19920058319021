import { acl } from '@/components/editor/config/acl/pages';
const pathToRegexp = require('path-to-regexp');

export default function({ store, redirect, rootState, route }) {
    if (route.path !== '/admin/login') {
        if (
            !store.state.user.auth ||
            !checkAcl(store.state.user.auth.permissions, route)
        ) {
            return redirect('/admin/login');
        }
    }
}

function checkAcl(permissions, route) {
    let check = false;

    for (const routePath in acl) {
        const routeMatch = pathToRegexp(routePath);
        if (route.path.match(routeMatch)) {
            acl[routePath].forEach(routePermission => {
                if (
                    permissions.includes(routePermission) ||
                    permissions.includes('admin')
                ) {
                    check = true;
                }
            });
        }
    }
    return check;
}
