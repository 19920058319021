export default ({ app }) => {
    if (process.browser) {
        window.addEventListener('copy', event => {
            const select = document.getSelection();

            if (select) {
                const regEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let isEmail = regEmail.test(select.toString());

                if (isEmail) {
                    // @ts-ignore
                    app.$gtm.pushEvent({ event: 'email_copy' });
                }
            }
        });
    }
};
