
    import { Component, namespace, Vue } from 'nuxt-property-decorator';

    let user = namespace('user');

    @Component
    export default class Header extends Vue {
        @user.Action logout;
        @user.State auth;
        @user.Getter isAdmin;

        logoutAction() {
            this.logout();
            // @ts-ignore
            this.$router.push('/admin/login');
        }
    }
