import { Component, namespace, Prop, Watch } from 'nuxt-property-decorator';
import { mixins } from 'nuxt-property-decorator';
import HandleResultMixin, { HandleType } from '@/mixins/handleResultMixin';

let form = namespace('form');

export interface IField {
    name: string;
    value?: string | boolean;
    title: string;
    type: string;
    error?: boolean;
    required: boolean;
}

@Component
export default class FormMixin extends mixins(HandleResultMixin) {
    @Prop() head;
    @Prop() text: string;
    @Prop() img;
    @Prop() code;
    @Prop() fields: IField[];
    @Prop() submitButtonText;
    @Prop() modal;
    @Prop() formId: string;
    @Prop() isButtonColorFull?;
    @Prop() borderColorButton?;
    @form.Action sendForm;
    formFields: IField[] = [];
    canSend = true;

    async onSubmit() {
        try {
            if (this.canSend) {
                this.canSend = false;
                // @ts-ignore
                const token = await this.$recaptcha.execute('form');
                let result = await this.sendForm({
                    fields: this.formFields,
                    id: this.formId
                });
                this.handleResult(result, HandleType.Modal, this.modal);
                this.clearFields();
                this.canSend = true;
            }
        } catch (error) {
            console.error(error);
            const result = {
                data: {
                    message:
                        'Ошибка отправки формы. Ошибка Recaptcha. Если вы не робот - перезагрузите страницу и попробуйте снова. Отключите VPN или Proxy.'
                }
            };
            this.handleResult(result, HandleType.Modal);
        }
    }

    @Watch('fields', { immediate: true, deep: true })
    changeFields(val, oldVal) {
        if (this.fields) {
            this.formFields = this.fields.map((field, index) => {
                let newField = { ...field };
                if (newField.type === 'checkbox') {
                    newField.value = true;
                } else {
                    newField.value = this.formFields[index]
                        ? this.formFields[index].value
                        : '';
                }
                return newField;
            });
        }
    }

    clearFields() {
        this.formFields = this.formFields.map(field => {
            let newField = { ...field };
            if (newField.type === 'checkbox') {
                newField.value = true;
            } else {
                newField.value = '';
            }
            return newField;
        });
    }
}
