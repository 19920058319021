import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class DetectScrollDirectionMixin extends Vue {
    direction: string = '';
    scrollTop: number = 0;
    hideScroll: boolean = false;
    lastScrollTop = 0;

    detectScrollDirection() {
        document.addEventListener('scroll', this.handler.bind(this), false);
    }

    handler() {
        // or window.addEventListener("scroll"....
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

        this.direction = this.scrollTop > this.lastScrollTop ? 'down' : 'up';

        this.lastScrollTop = this.scrollTop <= 0 ? 0 : this.scrollTop; // For Mobile or negative scrolling

        this.hideScroll = this.lastScrollTop === 0;
    }
}
