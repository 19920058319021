import { extend } from 'vee-validate';
import isEmail from 'validator/lib/isEmail';
import isAlpha from 'validator/lib/isAlpha';

function isPhone(value): boolean {
    return !!value.match(
        /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
    );
}

extend('required', {
    validate: value => !!value,
    message: 'Здесь необходимо ваше согласие'
});
extend('phone', {
    validate: value => isPhone(value),
    message: 'Некорректный номер телефона'
});
extend('email', {
    validate: value => isEmail(value),
    message: 'Некорректный email'
});
extend('name', {
    validate: value => isAlpha(value, 'ru-RU') || isAlpha(value),
    message: 'Некорректное имя'
});
