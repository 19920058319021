import Vue from 'vue';
import Icon from 'vue-awesome/components/Icon.vue';
import 'vue-awesome/icons/save';
import 'vue-awesome/icons/file-powerpoint';
import 'vue-awesome/icons/trash';
import 'vue-awesome/icons/chevron-down';
import 'vue-awesome/icons/chevron-up';
import 'vue-awesome/icons/toggle-on';
import 'vue-awesome/icons/toggle-off';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/download';
import 'vue-awesome/icons/plus';
import 'vue-awesome/icons/pen';
import 'vue-awesome/icons/backward';
import 'vue-awesome/icons/crop-alt';
import 'vue-awesome/icons/cloud-upload-alt';
import 'vue-awesome/icons/cloud-download-alt';
import 'vue-awesome/icons/copy';
import 'vue-awesome/icons/cog';
import 'vue-awesome/icons/chevron-right';
import 'vue-awesome/icons/fill-drip';
import 'vue-awesome/icons/brands/vk';
import 'vue-awesome/icons/brands/facebook-f';
import 'vue-awesome/icons/brands/youtube';
import 'vue-awesome/icons/brands/instagram';

import 'vue-awesome/icons/bold';
import 'vue-awesome/icons/italic';
import 'vue-awesome/icons/list-ul';
import 'vue-awesome/icons/list-ol';
import 'vue-awesome/icons/undo-alt';
import 'vue-awesome/icons/redo-alt';
import 'vue-awesome/icons/link';
import 'vue-awesome/icons/eraser';
import 'vue-awesome/icons/ban';
import 'vue-awesome/icons/external-link-alt';
import 'vue-awesome/icons/undo';
import 'vue-awesome/icons/redo';
import 'vue-awesome/icons/share-alt';
import 'vue-awesome/icons/text-height';
import 'vue-awesome/icons/marker';
import './icons/table';
import './icons/delete_table';
import './icons/delete_row';
import './icons/delete_col';
import './icons/combine_cells';
import './icons/add_row_before';
import './icons/add_row_after';
import './icons/add_col_after';
import './icons/add_col_before';
import './icons/pinq_fb';
import './icons/pinq_im';
import './icons/pinq_vk';
import './icons/pinq_youtube';
import './icons/pinq_tg';

// Register the component globally
Vue.component('font-awesome-icon', Icon);
