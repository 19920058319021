
    import { Component, Vue, mixins } from 'nuxt-property-decorator';
    import { PageComponent, PageOptions } from '@/store/page';
    import { namespace } from 'nuxt-property-decorator';
    import ImageMixin from '@/mixins/imageMixin';
    import isObj from 'is-obj';
    import { getComponentMeta } from '@/store/editor';
    const page = namespace('page');

    @Component
    export default class PageMixin extends mixins(Vue, ImageMixin) {
        @page.State options: PageOptions;
        @page.State components: PageComponent[];

        head() {
            let head = {
                title: this.options.meta.title,
                meta: [
                    {
                        hid: 'description',
                        name: 'description',
                        content: this.options.meta.description
                    }
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: process.env.CLIENT_HOST + this.routePath
                    }
                ]
            } as any;

            for (let key in this.options.meta.og) {
                let ogMeta = this.options.meta.og[key];

                if (key === 'image' && isObj(ogMeta) && ogMeta['src']) {
                    head.meta.push({
                        hid: `og:${key}`,
                        name: `og:${key}`,
                        content: this.imageSrc(ogMeta['src'])
                    });
                } else {
                    head.meta.push({
                        hid: `og:${key}`,
                        name: `og:${key}`,
                        content: ogMeta
                    });
                }
            }

            return head;
        }

        get routePath() {
            // @ts-ignore
            return this.$route.path === '/' ? '' : this.$route.path;
        }

        get editLink() {
            return `/edit/t-shirts`;
        }

        get activeComponents() {
            return this.components.filter(component => {
                return component.active;
            });
        }

        getComponentStyle(name) {
            let meta = getComponentMeta(name);
            //console.log(meta);
            if (meta) {
                return meta.style;
            }
            return {};
        }
    }
