
    import { Component, Vue } from 'nuxt-property-decorator';
    import Header from '@/layouts/default/Header.vue';
    import Footer from '@/layouts/default/Footer.vue';
    import ResultModal from '@/components/blocks/ResultModal.vue';
    import Button from '@/components/blocks/Button.vue';

    @Component({
        components: {
            Button,
            Footer,
            Header,
            ResultModal
        }
    })
    export default class Default extends Vue {}
